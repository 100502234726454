// routes
import { paths } from 'src/routes/paths';
// config
import { PATH_AFTER_LOGIN } from 'src/config-global';
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
  // {
  //   title: 'Home',
  //   icon: <Iconify icon="solar:home-2-bold-duotone" />,
  //   path: '/',
  // },

  {
    title: 'Automation',
    icon: <Iconify icon="vaadin:automation" />,
    path: paths.automation,
  },
  {
    title: 'Smart Home',
    icon: <Iconify icon="solar:home-2-bold-duotone" />,
    path: paths.smarthome,
  },
  {
    title: 'Smart City',
    icon: <Iconify icon="fluent:city-16-filled" />,
    path: paths.smartcity,
  },
  {
    title: 'Software',
    icon: <Iconify icon="eos-icons:software-outlined" />,
    path: paths.software,
  },

  {
    title: 'IoT',
    icon: <Iconify icon="eos-icons:iot" />,
    path: paths.IoT,
  },

  {
    title: 'Solar City',
    icon: <Iconify icon="mdi:solar-angle-outline" />,
    path: paths.solar,
  },
  {
    title: 'Agriculture',
    icon: <Iconify icon="healthicons:agriculture" />,
    path: paths.agriculture,
  },

  // {
  //   title: 'Components',
  //   icon: <Iconify icon="solar:atom-bold-duotone" />,
  //   path: paths.components,
  // },
  {
    title: 'Pages',
    path: '/pages',
    icon: <Iconify icon="solar:file-bold-duotone" />,
    children: [
      {
        subheader: 'Other',
        items: [
          { title: 'About us', path: paths.about },
          { title: 'Blogs', path: paths.contact },
          { title: 'Release Note', path: paths.contact },
          { title: 'Contact us', path: paths.contact },
          { title: 'FAQs', path: paths.faqs },
          // { title: 'Pricing', path: paths.pricing },
          // { title: 'Payment', path: paths.payment },
          // { title: 'Maintenance', path: paths.maintenance },
          { title: 'Coming Soon', path: paths.comingSoon },
          { title: 'Apply', path: paths.contact },
          { title: 'B2B', path: paths.contact },
        ],
      },
      {
        subheader: 'Shop and Learn',
        items: [
          { title: 'Shop', path: paths.product.root },
          // { title: 'Product', path: paths.product.demo.details },
          // { title: 'Checkout', path: paths.product.checkout },
          { title: 'Posts', path: paths.post.root },
          { title: 'Post', path: paths.post.demo.details },
        ],
      },
      {
        subheader: 'For Business',
        items: [
          { title: 'Raktch and Business', path: paths.contact },
          { title: 'Shop for Business', path: paths.contact },
          { title: 'Project for Business', path: paths.contact },
          // { title: 'Login', path: paths.authDemo.classic.login },
          // { title: 'Register', path: paths.authDemo.classic.register },
          // {
          //   title: 'Forgot password',
          //   path: paths.authDemo.classic.forgotPassword,
          // },
          // { title: 'New password', path: paths.authDemo.classic.newPassword },
          // { title: 'Verify', path: paths.authDemo.classic.verify },
          // { title: 'Login (modern)', path: paths.authDemo.modern.login },
          // { title: 'Register (modern)', path: paths.authDemo.modern.register },
          // {
          //   title: 'Forgot password (modern)',
          //   path: paths.authDemo.modern.forgotPassword,
          // },
          // {
          //   title: 'New password (modern)',
          //   path: paths.authDemo.modern.newPassword,
          // },
          // { title: 'Verify (modern)', path: paths.authDemo.modern.verify },
        ],
      },
      {
        subheader: 'Highlight',
        items: [
          { title: 'Smart Switch', path: paths.smartswitch },
          { title: 'Software Solutions', path: paths.software },
          { title: 'Solar City', path: paths.solar },
          { title: 'Robotics', path: paths.robotics },
          { title: 'Electrics', path: paths.electrics },
        ],
      },
      {
        subheader: 'Dashboard',
        items: [{ title: 'Dashboard', path: PATH_AFTER_LOGIN }],
      },
    ],
  },
  // {
  //   title: 'Docs',
  //   icon: <Iconify icon="solar:notebook-bold-duotone" />,
  //   path: paths.docs,
  // },
];
