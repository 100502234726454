import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
// routes
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1253 1203">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
        <path fill="#808282" opacity="1.000000" stroke="none"
          d="
        M377.000000,1204.000000
          C376.555542,1204.000000 376.111115,1204.000000 375.191589,1203.675537
          C358.771545,1194.893066 342.766327,1186.546143 326.894409,1177.953125
          C296.273743,1161.375488 267.000061,1142.684448 240.112350,1120.512573
          C222.827118,1106.259155 205.958099,1091.485718 189.239777,1076.567383
          C182.580856,1070.625366 176.699219,1063.783447 170.696854,1057.140625
          C159.962341,1045.260376 148.763626,1033.720581 138.931763,1021.123169
          C126.041595,1004.607239 113.712502,987.582092 102.214333,970.071899
          C91.620956,953.939758 81.760902,937.233643 72.833771,920.125000
          C63.437511,902.117249 55.083630,883.523804 47.081791,864.840393
          C36.193630,839.417725 27.290281,813.223877 21.076712,786.263184
          C16.123583,764.771606 11.840888,743.099731 8.102810,721.363037
          C5.630070,706.984253 4.700905,692.342773 2.947507,677.834961
          C2.627062,675.183594 1.665567,672.609680 0.999996,670.000000
          C1.000000,668.933289 1.000000,667.866638 1.322509,666.254395
          C1.430014,664.472595 1.215009,663.236267 1.000002,662.000000
          C1.000000,639.645752 1.000000,617.291565 1.341599,594.292114
          C1.455465,591.764587 1.227732,589.882324 0.999999,588.000000
          C1.000000,586.933289 1.000000,585.866638 1.350611,584.210449
          C2.141005,579.377380 2.462930,575.117737 3.041366,570.893127
          C5.310268,554.322266 7.440863,537.728027 10.030421,521.206421
          C14.061006,495.490936 20.126112,470.237396 27.845024,445.366882
          C39.919521,406.462555 54.889065,368.763824 74.544907,333.035095
          C82.046608,319.399078 89.717056,305.825867 97.986565,292.648254
          C118.296257,260.284332 141.662796,230.171448 167.579697,202.140289
          C179.640366,189.095718 192.535248,176.739853 205.806381,164.918732
          C221.030807,151.357727 236.798370,138.361008 252.856979,125.792473
          C277.689362,106.357010 304.319916,89.525597 332.321960,75.087433
          C351.848053,65.019577 371.893036,55.892891 392.038727,47.108414
          C417.410034,36.045361 443.805054,27.830683 470.612122,20.958235
          C497.129364,14.160087 523.892761,8.633110 551.171936,6.028810
          C567.903564,4.431468 584.683228,3.340018 601.436951,1.968018
          C602.642944,1.869256 603.813171,1.332901 605.000000,1.000003
          C619.687561,1.000000 634.375122,1.000000 649.757935,1.369390
          C665.592834,2.824497 680.760864,3.618049 695.863464,5.084536
          C708.919800,6.352332 722.119507,7.415357 734.872070,10.278370
          C762.429810,16.465240 790.174438,22.392366 817.030457,30.969070
          C843.292725,39.356174 868.897034,50.109592 894.143311,61.285656
          C919.344482,72.441803 943.116333,86.509911 966.234314,101.598015
          C990.182129,117.227715 1013.435913,133.809280 1034.524170,153.154037
          C1051.459473,168.689270 1068.505493,184.223557 1084.136108,201.028961
          C1100.701294,218.839279 1116.175415,237.741531 1131.177734,256.909180
          C1149.784668,280.682251 1165.946777,306.220673 1179.815674,333.019318
          C1190.361328,353.396484 1199.804932,374.378021 1208.978638,395.422089
          C1219.048340,418.521545 1227.176636,442.388092 1232.979980,466.929749
          C1237.754272,487.118774 1242.271362,507.400208 1245.896484,527.819275
          C1248.731812,543.789612 1249.980225,560.039429 1252.043945,576.150940
          C1252.426270,579.135193 1253.334473,582.052063 1254.000000,585.000000
          C1254.000000,611.687561 1254.000000,638.375122 1253.677734,665.676636
          C1253.570312,667.527039 1253.785156,668.763550 1254.000000,670.000000
          C1254.000000,671.066711 1254.000000,672.133362 1253.649414,673.789551
          C1252.533813,680.227234 1251.741943,686.072083 1251.009277,691.924316
          C1249.295898,705.608154 1248.409180,719.453918 1245.731689,732.945190
          C1241.472290,754.407837 1236.336060,775.712708 1231.012939,796.944458
          C1222.266479,831.830505 1209.169434,865.211426 1193.572510,897.512451
          C1184.022949,917.289246 1173.564209,936.751038 1161.929565,955.364075
          C1148.752319,976.444824 1134.361206,996.836548 1119.439331,1016.733948
          C1108.489990,1031.334229 1096.430054,1045.226929 1083.784546,1058.397583
          C1068.850952,1073.951172 1053.192871,1088.884888 1037.026123,1103.155273
          C1023.506958,1115.088501 1008.747253,1125.609863 994.642578,1136.888672
          C984.653320,1144.876709 969.871521,1147.024048 959.217834,1139.981201
          C954.031494,1136.552612 948.826904,1132.960693 944.341431,1128.695190
          C923.691345,1109.057983 904.836243,1087.767334 886.985046,1065.560913
          C877.295715,1053.507568 867.064758,1041.887817 857.448120,1029.778687
          C843.468628,1012.175781 829.812256,994.316101 816.038635,976.550049
          C802.059265,958.518677 787.979675,940.563293 774.173706,922.400024
          C759.392883,902.954224 744.872559,883.310120 730.254395,863.740784
          C725.374695,857.208313 720.543579,850.639526 715.588989,843.950562
          C725.076538,837.059875 734.302368,830.798096 743.042725,823.919556
          C760.989502,809.795532 779.302795,796.041504 796.300537,780.833374
          C814.674133,764.394226 831.403076,746.206238 846.221313,726.350098
          C864.574585,701.757141 879.751526,675.450012 889.966125,646.452942
          C900.130127,617.599731 905.502808,587.913330 903.938416,557.227783
          C903.326599,545.227905 902.429749,533.211792 900.938721,521.293640
          C897.540771,494.131897 889.328125,468.272156 878.031006,443.441284
          C864.693604,414.125763 846.022583,388.733429 821.365845,367.756714
          C802.127319,351.389648 780.610901,339.131683 756.974792,330.531616
          C742.323914,325.200867 727.277405,320.228333 711.669556,320.180023
          C602.012146,319.840637 492.353210,320.000061 382.694763,320.000061
          C380.926788,320.000061 379.158844,320.000061 377.000000,320.000061
          C377.000000,615.108154 377.000000,909.554077 377.000000,1204.000000
        z"/>
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default Logo;
