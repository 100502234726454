// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { usePathname } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _socials } from 'src/_mock';
// components
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'About Raktch',
    children: [
      { name: 'About us', href: paths.about },
      { name: 'Contact', href: paths.contact },
      { name: 'Raktch Leadership', href: paths.leadership },
      { name: 'FAQs', href: paths.faqs },
    ],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: paths.terms },
      { name: 'Privacy Policy', href: paths.privacy},
      { name: 'License', href: paths.licence },

    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'Contact Us', href: paths.contact },
      { name: 'raktchme@gmail.com.com', href: '#' },
      { name: 'support@raktch.com', href: '#' },
      { name: '+880 1581-677077', href: '#' }
    ],
  },
];

const LINKS_Pages = [
  {
    headline: 'Learn',
    children: [
      { name: 'Automation', href: paths.automation },
      { name: 'Smart Home', href: paths.smarthome },
      { name: 'Internet Of Things', href: paths.IoT },
      { name: 'Software', href: paths.software },
      { name: 'Robotics', href: paths.robotics },
      { name: 'Smart City', href: paths.smartcity },
      { name: 'Agriculture', href: paths.agriculture },
      { name: 'Electrics', href: paths.electrics },
      { name: 'Solar City', href: paths.solar },
    ],
  },
  {
    headline: 'Posts',
    children: [
      { name: 'Blogs & Posts', href: paths.post.root },
      { name: 'Releases note', href: paths.releases },
      { name: 'Coming Soon', href: paths.comingSoon },
      { name: 'Apply', href: paths.apply },

    ],
  },
  {
    headline: 'Raktch For Shop & Business',
    children: [
      { name: 'Shop & Products', href: paths.store },
      { name: 'Smart Switch', href: paths.smartswitch },
      { name: 'Raktch and Business', href: paths.contact },
      { name: 'Shop and Business', href: paths.contact },
      { name: 'Sales and Refund', href: paths.contact },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const pathname = usePathname();

  const isHome = pathname === '/';

  const simpleFooter = (
    <Box
      component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
          © All rights reserved
          <br />
          <Link href="http://www.raktch.com/"> Raktch Technology & Software </Link>
        </Typography>
      </Container>
    </Box>
  );

  const mainFooter = (
    <Box
      component="footer"
      sx={{
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Divider />

      <Container
        sx={{
          pt: 10,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        <Logo sx={{ mb: 3 }} />

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Raktch Technology & Software is a trailblazing technology company at the forefront of innovation,
              offering cutting-edge solutions to businesses worldwide.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                  href={social.path}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid xs={6} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS_Pages.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        {/* <Typography variant="body2" sx={{ mt: 10 }}>
        Raktch Technology & Software &reg; © 2021 - {new Date().getFullYear()}. All rights reserved.
        </Typography> */}
      </Container>

      <Container
        sx={{
          pt: 5,
          pb: 5,
          textAlign: { xs: 'center', md: 'unset' },
        }}
      >
        {/* <Logo sx={{ mb: 3 }} /> */}

        <Grid
          container
          justifyContent={{
            xs: 'center',
            md: 'space-between',
          }}
        >
          <Grid xs={8} md={3}>
            {/* <Typography
              variant="body2"
              sx={{
                maxWidth: 270,
                mx: { xs: 'auto', md: 'unset' },
              }}
            >
              Raktch Technology & Software is a trailblazing technology company at the forefront of innovation,
              offering cutting-edge solutions to businesses worldwide.
            </Typography> */}

            {/* <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{
                mt: 3,
                mb: { xs: 5, md: 0 },
              }}
            >
              {_socials.map((social) => (
                <IconButton
                  key={social.name}
                  sx={{
                    '&:hover': {
                      bgcolor: alpha(social.color, 0.08),
                    },
                  }}
                  href={social.path}
                >
                  <Iconify color={social.color} icon={social.icon} />
                </IconButton>
              ))}
            </Stack> */}
          </Grid>

          <Grid xs={6} md={6}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }}>
              {LINKS.map((list) => (
                <Stack
                  key={list.headline}
                  spacing={2}
                  alignItems={{ xs: 'center', md: 'flex-start' }}
                  sx={{ width: 1 }}
                >
                  <Typography component="div" variant="overline">
                    {list.headline}
                  </Typography>

                  {list.children.map((link) => (
                    <Link
                      key={link.name}
                      component={RouterLink}
                      href={link.href}
                      color="inherit"
                      variant="body2"
                    >
                      {link.name}
                    </Link>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>

        {/* <Typography variant="body2" sx={{ mt: 10 }}>

        Copyright © 2021 - {new Date().getFullYear()} <Link href="http://www.raktch.com/"> Raktch Technology & Software </Link> &reg;. All rights reserved.
        </Typography> */}

        <Box
      // component="footer"
      sx={{
        py: 5,
        textAlign: 'center',
        position: 'relative',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Logo sx={{ mb: 1, mx: 'auto' }} />

        <Typography variant="caption" component="div">
        Copyright 2021 - {new Date().getFullYear()} © All rights reserved
          <br />
          <Link href="http://www.raktch.com/"> Raktch Technology & Software </Link>
        </Typography>
      </Container>
    </Box>

      </Container>
      {/* <simpleFooter /> */}
    </Box>



  );
  return mainFooter;
  // return isHome ? simpleFooter : mainFooter;
}
