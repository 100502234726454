import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar/',
  contact: '/api/contactus/',
  leadership:'/api/leadership/',
  auth: {
    me: '/auth/users/me/',
    login: '/auth/token/login/',
    register: '/auth/users/',
    // me: '/api/auth/me',
    // login: '/api/auth/login',
    // register: '/api/auth/register',

  },
  blog: {
    list: '/api/post/blog/',
    create: '/api/post/blog/',
    update: '/api/post/blog/',
    details: '/api/post/blog/',
    latest: '/api/post/blog/',
    search: '/api/post/blog/',
  },
  fringe: {
    list: '/api/user/fringe/list/',
    create: '/api/user/fringe/list/',
    update: '/api/user/fringe/list/',
    details: '/api/user/fringe/list/',
    latest: '/api/user/fringe/list/',
    search: '/api/user/fringe/list/',
    transition: '/api/product/fringe/history/',
  },
  billing: {
    billing: '/api/company/acoount/billing/',
  },
  company: {
    list: '/api/company/list/',
    single: '/api/company/list/1/',
    create: '/api/company/list/',
    update: '/api/company/list/',
    details: '/api/company/list/',
    latest: '/api/company/list/',
    search: '/api/company/list/',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  notification: {
    list: '/api/notification/list/',
    update: '/api/notification/list/',
    // labels: '/api/mail/labels',
  },
  permission: {
    list: '/api/gen/user/permission/',
    single: '/api/gen/user/permission/',
    update: '/api/gen/user/up/permission/',
    // labels: '/api/mail/labels',
  },
  people: {
    list: '/api/post/people/',
    details: '/api/post/people/',
    latest: '/api/post/people/',
    search: '/api/post/people/',
  },
  post: {
    list: '/api/post/blog',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  portfolio: {
    list: '/api/post/portfolio/',
    details: '/api/post/portfolio/',
    latest: '/api/post/portfolio/',
    search: '/api/post/portfolio/',
  },
  project: {
    list: '/api/project/',
    details: '/api/project/',
    latest: '/api/project/',
    search: '/api/project/',
  },
  product: {
    list: '/api/product/list/',  // get and update (Only update first partition, not nested)
    post: '/api/product/post/', // Post all with nested information not images
    images: '/api/product/images/', // Post all extra images
    cover_image: '/api/product/images/cover',
    details: '/api/product/list/',
    search: '/api/product/list/',
    create: '/api/product/list/',
    review: '/api/product/review/',
    rating: '/api/product/rating/',
    category: '/api/product/category/',
    brand: '/api/product/brand/',
    color: '/api/product/color/',
    size: '/api/product/size/',
    warranty: '/api/product/warranty/'

  },
  sale: {
    list: '/api/product/sales/list/',
    post: '/api/product/sales/post/',
    items: '/api/product/sales/item/',
    search: '/api/product/sales/list/',
    create: '/api/product/sales/post/',
    update: '/api/product/sales/post/',
    cash:'/api/product/sales/cash/',
    cash_history:'/api/product/sales/cash/history/',
  },
  purchase: {
    list: '/api/supplychain/purchase/list/',
    post: '/api/supplychain/purchase/post/',
    items: '/api/supplychain/purchase/item/',

    // create: '/api/product/sales/post/',
    update: '/api/supplychain/purchase/post/',
    // cash:'/api/product/sales/cash/',
    history:'/api/supplychain/purchase/history/',
  },
  service: {
    list: '/api/post/service/',
    details: '/api/post/service/',
    latest: '/api/post/service/',
    search: '/api/post/service/',
  },
  expense: {
    list: '/api/expense/',
    post: '/api/expense/',
    items: '/api/expense/',
    search: '/api/expense/',
    create: '/api/expense/',
    update: '/api/expense/',
  },
  customer: {
    list: '/api/customer/list/',
    post: '/api/customer/list/',
    items: '/api/customer/list/',
    search: '/api/customer/list/',
    create: '/api/customer/list/',
    update: '/api/customer/list/',
  },
  supplier: {
    list: '/api/supplier/list/',
    post: '/api/supplier/list/',
    items: '/api/supplier/list/',
    search: '/api/supplier/list/',
    create: '/api/supplier/list/',
    update: '/api/supplier/list/',
  },
  user: {
    list: '/api/gen/user/list/',
    // post: '/api/gen/user/list/',
    update: '/api/gen/user/update/',
    pssReset: '/api/gen/user/re-set/pass/',
    // items: '/api/product/sales/item/',
    // search: '/api/product/sales/list/',
    create: '/api/gen/user/create/',
    // update: '/api/product/sales/post/',
  },
  order: {
    list: '/api/product/order/list/',
    create: '/api/product/order/list/',
    update: '/api/product/order/list/',
    details: '/api/product/order/list/',
    latest: '/api/product/order/list/',
    search: '/api/product/order/list/',
  },
};
